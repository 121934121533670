<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Header *"
                  v-model="header"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.header"
                  :error="!!errors.header"
                  :error-messages="errors.header"
                />
                <va-input
                  label="Sub Header *"
                  v-model="subheader"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.subheader"
                  :error="!!errors.subheader"
                  :error-messages="errors.subheader"
                />
                <va-input
                  label="Description *"
                  v-model="desc"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.desc"
                  :error="!!errors.desc"
                  :error-messages="errors.desc"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.img"
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.image" style="color: red;">{{ this.errors.image[0] }}</div>
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>
                <span v-if="errors.intro" style="color: red;">{{ this.errors.intro[0] }}</span>
                <va-input
                  label="link *"
                  v-model="href"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.href"
                  :error="!!errors.href"
                  :error-messages="errors.href"
                />
                <va-input
                  label="Index *"
                  v-model.number="index"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.index"
                  :error="!!errors.index"
                  :error-messages="errors.index"
                />
                <va-toggle label="HeaderIsSmall" small v-model="headerIsSmall"/>
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      label: '',
      desc: '',
      header: '',
      headerIsSmall: false,
      href: '',
      image: [],
      imageNew: '',
      index: 0,
      published: false,
      subheader: '',
      video: '',
      img: [],
      imgId: '',
      imgExisting: '',

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_MS_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    uploadImage (file) {
      console.log(file)
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.imageNew = response.data.patch
          this.imgId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/media/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        label: this.label,
        desc: this.desc,
        header: this.header,
        headerIsSmall: this.headerIsSmall,
        href: this.href,
        image: this.imageNew,
        index: this.index,
        published: this.published,
        subheader: this.subheader,
        // video: this.video
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/home/${this.$attrs.id}`, data)
          .then(response => {
            this.$router.push({ name: 'ms-capital-home' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/home`, data)
          .then(response => {
            this.$router.push({ name: 'ms-capital-home' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/home/${this.$attrs.id}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.label
            this.desc = response.data.desc
            this.header = response.data.header
            this.headerIsSmall = response.data.headerIsSmall
            this.href = response.data.href
            this.imgExisting = this.imgExisting = process.env.VUE_APP_MS_URL + response.data.image
            this.imageNew = response.data.image
            this.index = response.data.index
            this.published = response.data.published
            this.subheader = response.data.subheader
            this.video = response.data.published
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
